import { React, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getSessionProfile, getStops, showAlertModal } from './FmsSys';
import HeaderPanel from './HeaderPanel';
import FooterPanel from './FooterPanel';
import { debugInfo, debugDebug,  } from './FmsSys';

function StopList() {
  debugInfo('Stop list page load')
  const [stops, setStops] = useState([]);
  const location = useLocation();
  // get schlDate string
  debugDebug("stop list location object")
  debugDebug(location);

  const schlDate = location.state.schlDate;
  const truckId = location.state.truckId;

  const profile = getSessionProfile();


  useEffect(() => {
    debugDebug("fetching Stops");
    const fetchStops = () => {
      getStops(schlDate, profile.bu, truckId)
        .then(data => {
          debugDebug('Remote stop data fetched')
          debugDebug(data);
          return data;
        })
        .then(data => setStops(data))
        .catch(errMsg => {
          showAlertModal("Get Stops Failed", errMsg , null);
        });
    };
        
    fetchStops();
  }, [schlDate, profile.bu, truckId])


  return (
    <div>
      <HeaderPanel />
      <h4>
        <Link to="/" > Home </Link> \
        <Link to="/trucks" state={{ schlDate: schlDate }}> {schlDate} </Link> \
        truck {truckId}
      </h4>
      {stops.length > 0 && (
        <ul>
          {stops.map((stop) => (
            <li key={stop.seqNum + "-" + stop.address}> 
              Stop Id: {stop.seqNum} : 
              <Link to="/garments" state={{
                schlAddrRID: stop.schlAddrRID,
                seqNum: stop.seqNum,
                schlDate: schlDate,
                truckId: truckId

              }}>  {stop.items} garment{stop.items >1 ?'s':''}</Link>

              <br />Address: {stop.address}
              <br />Post Code: {stop.postCode}
              <br /> {stop.stopInfo} <br />
              <br />  
            </li>
            
          ))}
        </ul>
      )}

      <FooterPanel />
    </div>

  )
}

export default StopList