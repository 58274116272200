import React from 'react';
import { Link } from 'react-router-dom';

function LoginFailed() {
  return (
    <div>
        <div>Login Failed, Please make sure username and password is correct</div>
        <Link to="/login">Login</Link>
    </div>
    
  )
}

export default LoginFailed