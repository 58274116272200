import React from 'react'
import { debugInfo } from './FmsSys';

function FooterPanel() {
  debugInfo ("FooterPanel page loading");
  return (
    <div>Indetech Data Management</div>
  )
}

export default FooterPanel