import { React, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { debugDebug, getSessionProfile } from './FmsSys';
import { debugInfo } from './FmsSys';
const HeaderPanel = () => {
  
  debugInfo("HeaderPanel page loading");

  let profile = getSessionProfile();
  let navigate = useNavigate();

  useEffect(() => {
    if (profile.isLogined !== true) {
      debugDebug("redirect to login page");
      navigate('/login');
    }
  });
  const onLogoutClick = (e) => { sessionStorage.clear(); }

  return <header>
    <div className='app_header'>
      <nav>
        <div className='app_logo'>
          <h1>FMS</h1>
        </div>
        <div className='login_status'>
          {
            profile.isLogined !== true
              ? <Link to="/login">Login</Link>
              : <div><div>UserId:{profile.userId}</div> <Link to="/" onClick={onLogoutClick}>Logout</Link> </div>
          }
        </div>
      </nav>
    </div>

  </header>;
};

export default HeaderPanel;
