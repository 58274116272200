import { React, useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import SignatureCanvas from 'react-signature-canvas';
//sample: https://dev.to/ma7eer/create-a-signature-pad-in-react-3mmi 

import HeaderPanel from './HeaderPanel';
import FooterPanel from './FooterPanel';
import { showConfirmModal, showAlertModal, getSessionProfile, getGarments, putConfirmation } from './FmsSys';
import { debugInfo, debugDebug } from "./FmsSys";
function GarmentList() {
  debugInfo("GarmentList page loading");
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const location = useLocation();
  const [base64Img, setBase64Img] = useState('');

  debugDebug("item list location object")
  debugDebug(location);

  const schlDate = location.state.schlDate;
  const truckId = location.state.truckId;
  const schlAddrRID = location.state.schlAddrRID;
  const seqNum = location.state.seqNum;

  const profile = getSessionProfile();

  const sigCanvas = useRef({});

  const clearCanvas = () => { sigCanvas.current.clear(); };
  const trimImg = () => {
    let trimDataUrl = sigCanvas
      .current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    console.log(trimDataUrl);
    putConfirmation({
      "fmsUser": profile.userId,
      "bu": profile.bu,
      "bud": '',
      "acct": '',
      "doneDate": new Date(),
      "base64Signature": trimDataUrl,
      "cfsList": [
        {
          "cfsRid": 0,
          "schlType": "string"
        }
      ]
    })
      .catch(errMsg => { showAlertModal("Update confirmation Failed", errMsg, null); });
    setBase64Img(trimDataUrl);
  }

  useEffect(() => {
    const fetchItems = () => {
      getGarments(schlDate, profile.bu, truckId, schlAddrRID)
        .then(data => {
          debugDebug('Remote Item data fetched')
          return data;
        })
        .then(data => setItems(data))
        .catch(errMsg => {
          showAlertModal("Get Garments Failed", errMsg, null);
        })
        ;
    }
    fetchItems();
  }, [schlDate, profile.bu, truckId, schlAddrRID]);

  return (
    <div className='item_list'>
      <HeaderPanel />
      <div>
        <h4>
          <Link to="/" > Home </Link> \
          <Link to="/trucks" state={{ schlDate: schlDate }}> {schlDate} </Link> \
          <Link to="/stops" state={{ truckId: truckId, schlDate: schlDate }}> truck {truckId} </Link> \
          Stop SeqNum {seqNum}
        </h4>
        <ul>  </ul>

        {items.length > 0 && (
          <ul>
            {items.map((item) => (
              <div>
                <li key={item.furTag}>
                  <div>{(item.scheduleType === 'D') ? 'Delivery' : 'Pickup'} : {(item.doneDate === null) ? 'Todo' : 'Done'}</div>
                  <div>Tag:{item.furTag} </div>
                  <div>Value: {item.furValue}</div>
                  <div>Desc:{item.calc_Desc} <br />Cust:{item.custName}<br />Note:{item.schlNote}</div>
                  <div>
                  </div>
                  <br />
                </li>
              </div>
            ))}
          </ul>
        )}

        <button onClick={() => { navigate('/stops', { state: { truckId: truckId, schlDate: schlDate } }) }}>Cancel</button>
        <br />

        <div>
          <div className='signatureCanvasBackground'>This signatrue Canvas</div>
          <SignatureCanvas
            ref={sigCanvas}
            penColor='green'
            canvasProps={{ className: "signatureCanvas" }}
          //dotSize={2}     
          //minWidth={0.1}
          //maxWidth={1}

          />
          <div>
            <button onClick={() => { clearCanvas(); }}>Clear</button>
            <button onClick={() => { trimImg(); }}>Trim</button>
            <button onClick={() => { showConfirmModal('Modal Title', 'Alert Message', () => { alert("YES"); }, () => { alert("NO"); }) }}>Alert Submit</button>
          </div>
        </div>
        {/* <div>
            <SignatureCanvas penColor='green' canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} />
          </div> */}

        <div>
          <img src={base64Img} />
        </div>
      </div>

      <FooterPanel />
    </div>
  )
}

export default GarmentList