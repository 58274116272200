import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import "./sigCanvas.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import './App.css';

import { APP_ROOT_DIRECTORY } from './components/config';
import About from './components/About';
import Trucks from './components/Trucks';
import CustSign from './components/CustSign';
import Default from "./components/Default";
import GarmentList from './components/GarmentList';
import LoginPanel from './components/LoginPanel';
import StopList from './components/StopList';
import LoginFailed from './components/LoginFailed';
import LoginExpried from './components/LoginExpried';
import DeviceNotSupported from './components/DeviceNotSupported';
import { debugInfo,setDefaultWinSize } from './components/FmsSys';


function App() {
  debugInfo("React App version: " + React.version);
  // setDefaultWinSize(361,741);
  
  return (
    <div>
      <Routes>
        {/* landing page */}
        <Route path="/" element={<Default />} ></Route>

        {/* login page */}
        <Route path="/login" element={<LoginPanel />} ></Route>
        {/* login failed */}
        <Route path="/loginfailed" element={<LoginFailed />} ></Route>
        {/* login expried */}
        <Route path="/loginexpried" element={<LoginExpried />} ></Route>
        
        {/* truck list */}
        <Route path="/trucks" element={<Trucks />} ></Route>

        {/* stop list */}
        <Route path="/stops" element={<StopList />} ></Route>

        {/* garment list */}
        <Route path="/garments" element={<GarmentList />} ></Route>

        {/* sign items  */}
        <Route path="/signitems" element={<CustSign />} ></Route>

        {/* about  */}
        <Route path="/about" element={<About />} ></Route>
        
        {/* not supported */}
        
        <Route path="/invaliddevice" element={<DeviceNotSupported />} ></Route>
        {/* other pages  */}        
        {<Route path="*" element={<Navigate to="/" />} ></Route>}
      </Routes>
    </div>
  );
}

export default App;
