import React from 'react';
import { Link } from 'react-router-dom';

function LoginExpried() {
  return (
    <div>
        <div>Login Expried, Please login again</div>
        <Link to="/login">Login</Link>
    </div>
    
  )
}

export default LoginExpried