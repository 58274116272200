import React from 'react';
import HeaderPanel from './HeaderPanel';
import FooterPanel from './FooterPanel';
import { Link } from 'react-router-dom';

// import { Image, PixelRatio, ScrollView, StyleSheet, Text, View } from "react-native";
function About() {

    const screenInfo = "Total width/height: " + window.screen.width + "*" + window.screen.height + "</ br>" +
        "Available width/height: " + window.screen.availWidth + "*" + window.screen.availHeight + "</ br>" +
        "Color depth: " + window.screen.colorDepth + "</ br>" +
        "Color resolution: " + window.screen.pixelDepth + "</br>" +
        "DevicePixelRatio:" + window.devicePixelRatio;
    return (
        <div>
            <HeaderPanel />
            <div>
                <Link to="/" > Home </Link> \
                <h1>about</h1>
                <p>FMS React App. Version: 0.0.0.12</p>
                <p>Total width/height:  {window.screen.width} x {window.screen.height} </p>
                <p>Available width/height: {window.screen.availWidth} x {window.screen.availHeight}  </p>
                <p> Color depth: {window.screen.colorDepth} </p>
                <p>Color resolution: {window.screen.pixelDepth}  </p>
                <p>DevicePixelRatio: {window.devicePixelRatio}</p>
            </div>
            <FooterPanel />
        </div>
    )
}

export default About