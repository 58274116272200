import React from 'react'
import { debugInfo } from './FmsSys';

function CustSign() {
  debugInfo("CustSign loading");
  return (
    <div>Customer Sign</div>
  )
}

export default CustSign