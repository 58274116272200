export const API_SITE_URL_ROOT = "https://furvault.idm.today/fmsapidev/api";
// export const API_SITE_URL_ROOT = "https://localhost:7041/api";
// export const API_SITE_URL_ROOT = "https://tm2.dynacheck.net/fmsapidev/api";
export const SCREEN_WIDTH = 1000;
export const SCREEN_HEIGHT = 600;

// follow up get pixel of screen
// https://reactnative.dev/docs/pixelratio#get review the screen size
// it relate to window pixel, pixelRatio and fontscale (can be read from react native, but react native can't be used for PWA)
// the viewport is the other UM that present the screen size
// look into window.devicePixelRatio
// https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Testing_media_queries

// follow up sub folder 
// https://skryvets.com/blog/2018/09/20/an-elegant-solution-of-deploying-react-app-into-a-subdirectory/
// https://www.fullstacktutorials.com/deploy-react-app-subdirectory-10.html
// https://muffinman.io/blog/react-router-subfolder-on-server/
// https://scottvinkle.me/blogs/work/how-to-deploy-a-react-app-to-a-subdirectory

/*
<div id='testdiv' style='height: 1in; left: -100%; position: absolute; top: -100%; width: 1in;'></div>
<script type='text/javascript'>
  var devicePixelRatio = window.devicePixelRatio || 1;
  dpi_x = document.getElementById('testdiv').offsetWidth * devicePixelRatio;
  dpi_y = document.getElementById('testdiv').offsetHeight * devicePixelRatio;
  
  console.log(dpi_x, dpi_y);
</script>
*/