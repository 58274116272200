import React from 'react';
import { useState } from 'react';
import { debugDebug, getRemoteProfile, isDeviceOkToRun, showAlertModal } from './FmsSys';
import { debugInfo } from './FmsSys';
import { useNavigate } from "react-router-dom";

function LoginPanel() {
  debugInfo("login page loading");
  let navigate = useNavigate();
  if (isDeviceOkToRun() === false) {
    window.location.href = "/invaliddevice"
  }

  const onSubmit = function (e) {
    e.preventDefault();
    if (!userid) { alert("please enter userid"); return; }
    if (!password) { alert("please enter password"); return; }

    getRemoteProfile(userid, password)
      .then((profile) => {
        if (profile.isLogined === true) {
          // window.location.href = "/default";
          navigate("/default");
        }
        else {
          showAlertModal("Login Failed", "Login Failed" , () => {
            navigate("/loginfailed");
          });
        }
        return profile;
      })
      .catch((ex) => {
        showAlertModal("Login Failed", "Login Failed:" + ex, () => {
          navigate("/loginfailed");
          //window.location.href = "/loginfailed";
        });
      });
  };

  const [userid, setUserId] = useState('')
  const [password, setPassword] = useState('')

  return <form id="login_panel" onSubmit={onSubmit}>
    <div>

      <div>Please Login</div>
      <ul><label>User Name</label>
        <input
          type="text"
          id="userid"
          value={userid}
          onChange={(e) => setUserId(e.target.value)}
        /></ul>
      <ul><label>Password</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        /></ul>
      <ul><input type="submit" id="click_to_login" value="Login" /></ul>

    </div>
  </form>;
}

export default LoginPanel;
