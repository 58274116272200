import { React, useState } from 'react';
import { Link } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";

import HeaderPanel from './HeaderPanel';
import FooterPanel from './FooterPanel';
import { getSessionProfile } from "./FmsSys";
import { debugInfo } from './FmsSys';
function Default() {

  debugInfo("Default page loading");

  const profile = getSessionProfile();

  const [schlDate, setSchlDate] = useState(new Date(2019,10,14));

  return (
    <div>
      <HeaderPanel />
      {
        profile.isLogined === true
          ? <div>
            <DatePicker selected={schlDate} onChange={(date) => setSchlDate(date)} />
            <ul><Link to="/trucks" state={{schlDate:schlDate.toISOString().substring(0, 10) }}>trucks</Link></ul>
            <ul><Link to="/about">about</Link></ul>
          </div>

          : <p>Pelase Login</p>
      }
      <FooterPanel />
    </div>

  )
}

export default Default