import { API_SITE_URL_ROOT, SCREEN_HEIGHT, SCREEN_WIDTH } from "./config";
import { confirmAlert } from "react-confirm-alert";

export const DebugLevel = {
  Default: 0,

  All: 0,
  Debug: 1,
  Detail: 2,
  Info: 3,
  Warn: 4,
  Error: 5,
  Off: 9,
};

export const APP_WINDOW_WIDTH = 360;
export const APP_WINDOW_HEIGHT = 740;

var is_Initialzed = false;

const screenInfo = "Total width/height: " + window.screen.width + " x " + window.screen.height + "</ br>" +
  "Available width/height: " + window.screen.availWidth + " x " + window.screen.availHeight + "</ br>" +
  "Color depth: " + window.screen.colorDepth + "</ br>" +
  "Color resolution: " + window.screen.pixelDepth + "</br>" +
  "DevicePixelRatio:" + window.devicePixelRatio;

export const isDeviceOkToRun = () => {
  let screen_w = window.screen.availWidth;
  let screen_h = window.screen.availHeight;
  if (screen_w < SCREEN_WIDTH || screen_h < SCREEN_HEIGHT) { return false; }
  else { return true; }
}

export const setDefaultWinSize = () => {
  if (is_Initialzed === false) {
    // Size window after open the app
    window.resizeTo(APP_WINDOW_WIDTH, APP_WINDOW_HEIGHT);

    window.addEventListener('resize', () => {
      window.resizeTo(APP_WINDOW_WIDTH, APP_WINDOW_HEIGHT);
      debugDebug('keep the windows size ' + APP_WINDOW_WIDTH + ',' + APP_WINDOW_HEIGHT);
    });
    is_Initialzed = true;
    debugDebug('default size initialzed');
  }
}

function getTimeStamp() { return (new Date()).toISOString(); }

function debugLog(debugLevel, leveltext, msg) {
  if (debugLevel > DebugLevel.Default) {
    console.log(getTimeStamp() + ' ' + leveltext);
    if (debugLevel === DebugLevel.Warn) { console.warn(msg); }
    else if (debugLevel === DebugLevel.Error) { console.error(msg); }
    else { console.log(msg); }
  }
}

export const debugError = (msg) => {
  debugLog(DebugLevel.Error, '== Error ==', msg);
}
export const debugWarn = (msg) => {
  debugLog(DebugLevel.Warn, '== Warn  ==', msg);
}
export const debugInfo = (msg) => { debugLog(DebugLevel.Info, "== Info  ==", msg); }
export const debugDetail = (msg) => { debugLog(DebugLevel.Detail, '== Detail ==', msg); }
export const debugDebug = (msg) => { debugLog(DebugLevel.Debug, '== Debug ==', msg); }
export const debugAll = (msg) => { debugLog(DebugLevel.All, '== All   ==', msg); }

// use this to move the setting from code to config.js
export const API_SITE_URL = API_SITE_URL_ROOT;


const API_URL_DOC = API_SITE_URL.replace("/api", "") + "/swagger/index.html"

const API_URL_Trucks = API_SITE_URL + "/Trucks";
const API_URL_Trucks_Stops = API_URL_Trucks + "/stops";
const API_URL_Trucks_Garments = API_URL_Trucks + "/garments";
const API_URL_Trucks_Confirm = API_URL_Trucks + "/confirm";

const API_URL_Login = API_SITE_URL + "/Login";
const API_URL_Login_Loginjwt = API_URL_Login + "/loginjwt";
const API_URL_Login_Refreshjwt = API_URL_Login + "/refreshjwt";

export const getDeviceId = () => {
  return "fms-react-device-id";
}

export const UnsignInedProfile = {
  userId: '',
  lastName: '',
  fristName: '',
  bu: "",
  bud: "",
  isLogined: false,
  loginTime: '',
  token: {
    accessToken: '',
    accessTokenExpire: '',
    refreshToken: '',
    refreshTokenExpire: ''
  },
  lastDeviceId: ''
};

// turn code to promise style
/* code sample
function feedReducer(args){
    return new Promise((res,rej)=>{
    res(args);
  })
}

const somefunction = async (...args) => {
  let result = await feedReducer(args);
  console.log(result);
  // do something
}

somefunction('hello');
*/
const userProfileSessionKey = "UserProfile";
export const setSessionProfile = (userProfile) => {

  debugDebug("Save profile to session");
  debugDebug(userProfile);

  let saveProfile = {
    token: {
      accessToken: userProfile.token.accessToken,
      accessTokenExpire: userProfile.token.accessTokenExpire,
      refreshToken: userProfile.token.refreshToken,
      refreshTokenExpire: userProfile.token.refreshTokenExpire
    },
    userId: userProfile.userId,
    lastName: userProfile.lastName,
    fristName: userProfile.fristName,
    bu: userProfile.bu,
    bud: userProfile.bud,
    isLogined: userProfile.isLogined,
    loginTime: userProfile.loginTime,
    lastDeviceId: userProfile.lastDeviceId
  };

  // if ((window.sessionStorage.getItem(userProfileSessionKey) === null) == false) {
  //   window.sessionStorage.removeItem(userProfileSessionKey);
  // }

  window.sessionStorage.setItem(userProfileSessionKey, JSON.stringify(saveProfile));
};

export const getSessionProfile = () => {
  const session = window.sessionStorage;
  // const profileContextKey = "ProfileContextKey";

  const sessionProfileData = session.getItem(userProfileSessionKey);

  if (sessionProfileData === null) {
    setSessionProfile(UnsignInedProfile);
    return UnsignInedProfile;
  }
  else {
    const sessionUserProfile = JSON.parse(sessionProfileData);
    return sessionUserProfile;
  }
}
export const getTrucks = async (schlDate, bu) => {

  const requstOptions = await jwtGetRequestOptions();

  // set fecthing to true
  const idmDataRepsonse = await fetch(API_URL_Trucks + '?date=' + schlDate + '&bu=' + bu
    , requstOptions)
    .then((response) => {
      if (response.ok) { return response.json(); }
      else { throw response; }
    })
    .catch((error) => {
      debugError("Remote Get Trucks Error");
      console.error("remote get trucks error", error);
    })
    .finally(() => {
      // set fecthing to false
    });
  if (idmDataRepsonse.isOk === true) {
    const trucks = idmDataRepsonse.data;
    return trucks;
  }
  else { throw idmDataRepsonse.errorMessage; }

}

export const getStops = async (schlDate, bu, truckId) => {
  // set fecthing to true
  const idmDataRepsonse = await fetch(API_URL_Trucks_Stops + '?date=' + schlDate + '&bu=' + bu + '&truckid=' + truckId
    , await jwtGetRequestOptions())
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      else { throw response; }
    })
    .catch((error) => {
      debugError("Remote Get Stops Error");
      console.error("remote get stops error", error);
    })
    .finally(() => {
      // set fecthing to false
    });

  if (idmDataRepsonse.isOk === true) {
    let stops = idmDataRepsonse.data
    return stops;
  }
  else { throw idmDataRepsonse.errorMessage; }

}

const jwtGetRequestOptions = async () => {
  const getRequestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + (await getJwtSessionAccessToken())
    }
  }
  return getRequestOptions;
};

const getJwtSessionAccessToken = async () => {
  const currentProfile = getSessionProfile();
  const currentDateTime = new Date();
  const accessTokenExpireTime = new Date(currentProfile.token.accessTokenExpire);
  const refreshTokenExpireTime = new Date(currentProfile.token.refreshTokenExpire);

  debugDebug('current date time:');
  debugDebug(currentDateTime);
  debugDebug('current profile:');
  debugDebug(currentProfile);

  debugDebug('expire time:')
  debugDebug(accessTokenExpireTime);

  if (refreshTokenExpireTime < currentDateTime) {
    debugDebug('refresh token expried');
    window.sessionStorage.clear();
    window.location.href = "/loginexpried";
  }
  else if (accessTokenExpireTime < currentDateTime) {
    debugDebug('refresh jwt token');
    await refreshJwtToken();
    const newProfile = getSessionProfile();
    debugDebug('refreshed new profile');
    debugDebug(newProfile);

    return newProfile.token.accessToken;
  }
  else {
    debugDebug('use current Token');
    return currentProfile.token.accessToken;
  }
}

const jwtPutRequestOptions = async (putData) => {
  const putRequestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + (await getJwtSessionAccessToken())
    },
    body: JSON.stringify(putData)
  }
  return putRequestOptions;

};

const refreshJwtToken = async () => {

  const currentProfile = getSessionProfile();

  const requestData = {
    userId: currentProfile.userId,
    accessToken: currentProfile.token.accessToken,
    refreshToken: currentProfile.token.refreshToken,
    deviceId: getDeviceId()
  };

  debugDebug("refresh toke request data");
  debugDebug(requestData);

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(requestData)
  };

  // the login does need to adding jwt token
  // set fecthing to true
  const idmDataRepsonse = await fetch(API_URL_Login_Refreshjwt, requestOptions)
    .then((response) => {
      if (response.ok) {
        debugDebug("refresh token is ok");
        return response.json();
      }
      else {
        debugDebug("refresh token falied");
        // window.sessionStorage.clear();
        throw response;
      }
    })
    .catch((error) => { console.error("remote refresh jwt token error", error); })
    .finally(() => {
      // set fecthing to false
      debugDebug("refresh token is finally");
    });
  debugDebug("save refreshed profile");
  if (idmDataRepsonse.isOk === true) {
    let profile = idmDataRepsonse;
    setSessionProfile(profile);
    return profile;
  }
  else { throw idmDataRepsonse.errorMessage; }
};

export const getRemoteProfile = async (userid, password) => {
  debugInfo(userid + "," + password);

  const requestData = {
    loginId: userid,
    password: password,
    deviceId: getDeviceId()
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(requestData)
  }

  // the login does need to adding jwt token
  // set fecthing to true
  const idmDataRepsonse = await fetch(API_URL_Login_Loginjwt
    , requestOptions)
    .then((response) => {
      if (response.ok) {
        debugDebug("remote login response ok");
        return response.json();
      }
      else { throw response; }
    })
    .catch((error) => {
      console.error("remote login error", error);
      debugError(error);
    })
    .finally(() => {
      // set fecthing to false
    });

  if (idmDataRepsonse.isOk === true) {
    const profile = idmDataRepsonse;
    setSessionProfile(profile);
    return profile;
  }
  else { throw idmDataRepsonse.errorMessage; }
};

const jwtPostRequestOptions = async (postData) => {
  const postRequestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + (await getJwtSessionAccessToken())
    },
    body: JSON.stringify(postData)
  }
  return postRequestOptions;
}

export const getGarments = async (schlDate, bu, truckId, schlAddrRID) => {
  debugDebug('get garments date:' + schlDate + ",bu:" + bu + ",truckId:" + truckId + ",schlAddrRID:" + schlAddrRID);

  // set fecthing to true
  const idmDataRepsonse = await fetch(API_URL_Trucks_Garments + '?date=' + schlDate + '&bu=' + bu + '&truckId=' + truckId + '&addrRid=' + schlAddrRID
    , await jwtGetRequestOptions())
    .then((response) => {
      if (response.ok) { return response.json(); }
      else { throw response; }
    })
    .catch((error) => {
      debugDebug('Remote get Garments Error');
      console.error("remote get garments error", error);
    })
    .finally(() => {
      // set fecthing to false
    });
  if (idmDataRepsonse.isOk === true) {
    const garments = idmDataRepsonse.data;
    debugDebug("remote garments list retrived and loging it");
    debugDebug(garments);
    return garments;
  }
  else { throw idmDataRepsonse.errorMessage; }

}
export const showAlertModal = (alertTitle, alertMessage, okAction) => {
  confirmAlert({
    title: alertTitle,
    message: alertMessage,
    closeOnClickOutside: false,
    buttons: [
      {
        label: 'Ok',
        onClick: () => { if (okAction != null) { okAction(); } }
      }
    ]
  });

}
export const showConfirmModal = (confirmTitle, confirmMessage, yesAction, noAction) => {
  confirmAlert({
    title: confirmTitle,
    message: confirmMessage,
    closeOnClickOutside: false,
    buttons: [
      {
        label: 'Yes',
        onClick: () => { if (yesAction != null) { yesAction(); } }
      },
      {
        label: 'No',
        onClick: () => { if (noAction != null) { noAction(); } }
      }
    ]
  });
};



export const putConfirmation = async (confirmation) => {

  // const requestOptions = {
  //   method: 'PUT',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify(confirmation)
  // };

  const requestOptions = await jwtPutRequestOptions(confirmation);
  const idmDataRepsonse = await fetch(API_URL_Trucks_Confirm
    , requestOptions)
    .then((response) => {
      if (response.ok) { return response.json(); }
      else { throw response; }
    })
    .catch((error) => {
      debugDebug('Remote put confirmation failed');
      console.error("Remote put confirmation failed", error);
    })
    .finally(() => {
      // set fecthing to false
    });
    if (idmDataRepsonse.isOk === true) { 
      const isConfirmed= idmDataRepsonse.data; 
      return isConfirmed;
    }
    else { throw idmDataRepsonse.errorMessage; }
}