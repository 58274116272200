import React, { useEffect, useState } from 'react'
import HeaderPanel from './HeaderPanel'
import FooterPanel from './FooterPanel'
import { useLocation, Link } from 'react-router-dom';
import { getSessionProfile, getTrucks, showAlertModal } from './FmsSys';
import { debugInfo, debugDebug } from './FmsSys';

function Trucks() {
  debugInfo("Trucks page loading");
  const [trucks, setTrucks] = useState([]);
  const location = useLocation();
  // get schlDate string
  const schlDate = location.state.schlDate;
  debugDebug(schlDate);

  const profile = getSessionProfile();
  debugDebug("The bu is " + profile.bu);

  useEffect(() => {
    debugDebug("fetching truck");
    const fetchTruck = () => {
      getTrucks(schlDate, profile.bu)
        .then(data => {
          debugDebug('truck fetched')
          return data;
        })
        .then(data => setTrucks(data))
        .catch(errMsg =>{
          showAlertModal("Get Trucks Failed", errMsg , null);
        });
    };
    fetchTruck();
  }, [schlDate, profile.bu])

  return (
    <div>
      <HeaderPanel />
      <Link to="/" > Home </Link>
      <div>Trucks for {profile.bu} on {schlDate}  </div>
      {trucks.length > 0 && (
        <ul>
          {trucks.map((truck) => (
            <li key={truck.truckId}>truck {truck.truckId} : {truck.items} items (value: ${truck.value})
              <Link to="/stops" state={{ truckId: truck.truckId, schlDate: schlDate }}> {truck.stops} stops</Link>
            </li>
          ))}
        </ul>
      )}
      <FooterPanel />
    </div>
  )
}

export default Trucks